"use client";

import { Grid } from "@/lib/antd";

const { useBreakpoint } = Grid;
export default function AuthLayout(props: any) {
  const screens = useBreakpoint();

  return (
    <>
      {screens?.md == undefined || screens?.md ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100vh",
            width: "100vw",
          }}
        >
          <div
            style={{
              flexShrink: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              overflow: "hidden",
              background: "#151515",
              width: "50%",
            }}
          >
            <video
              autoPlay
              loop
              muted
              style={{
                height: "100vh",
                width: "auto",
                objectFit: "cover",
              }}
            >
              <source src="/videos/kiwi_video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div style={{ width: "100%" }}>{props.children}</div>
        </div>
      ) : (
        <>
          <div>{props.children}</div>
        </>
      )}
    </>
  );
}

